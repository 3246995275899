import React from 'react';
import MyForm from './form/MyForm';

const ContactScreen = () => {
	return (
		<>
			<div className='header-contact'>
				<div className='container'>
					<div className='w-layout-grid grid-content-small'>
						<div
							id='w-node-_1c1ad4f9-28f1-4437-171c-653efad05312-07b96c26'
							className='content-header-wrapper'
						>
							<div className='content-top-wrapper'>
								<h1 className='heading-white'>Let us know how we can help.</h1>
							</div>
						</div>
						<div data-size='compact'>
							<div data-size='compact' className='content-form'>
								<div data-size='compact' className='form-block-contact w-form'>
									<MyForm />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='section-contact-detail'>
				<div className='container-large'>
					<div className='location-detail-wrapper'>
						<div className='location-details'>
							<div className='w-layout-grid grid-content-green'>
								<a href='#' className='contact-detail w-inline-block'>
									<div className='contact-icon-dark'>
										<img
											src='images/All-Icons-27.svg'
											loading='lazy'
											alt='Location pin icon'
											className='contact-icon'
										/>
									</div>
									<div>
										<p className='contact-title'>Address</p>
										<p>
											Bärenpl. 2, 3011
											<br />
											Bern, Switzerland
										</p>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactScreen;
