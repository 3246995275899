import React from 'react';

const CareersScreen = () => {
	return (
		<>
			<div id='hero-overlay' className='section-hero wf-section'>
				<div className='centered-container w-container'>
					<h1 className='heading-5'>We see you. Join our team.</h1>
					<div className='div-block-82'>
						<a href='/careers#open-positions' className='button-primary w-button'>
							Explore Opportunities
						</a>
					</div>
				</div>
			</div>

			<div className='section-client'>
				<div className='container-large'>
					<div className='client-block'>
						<div className='w-layout-grid logo-grid-horizontal hide'>
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='rigid logo 2' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
						</div>
					</div>
				</div>
			</div>

			<div className='section-content big-padding'>
				<div className='container-large'>
					<div className='container-large big-padding'>
						<div className='w-layout-grid grid-content-video margin-top'>
							<div className='background-video-3 w-background-video w-background-video-atom background-video-two'></div>
							<div
								id='w-node-_220af361-995a-5947-6ba3-0710b1cffb9f-acb96c1b'
								className='content-wrapper'
							>
								<h2>Do you?</h2>
								<p className='body-large'>
									Thrive in a remote work environment but enjoy the support of a collegial team?
									<br />
									<span className='text-span-7'>
										accounting jobs, work from home jobs near me, accounts payable jobs
										<br />
									</span>
									Bring a professional, and positive approach to your work while taking pride in
									your clients' success?
									<br />
									<br />
									<span className='text-span-5'>We would love to meet you.</span>
								</p>
								<div className='button-spacing'>
									<a
										href='/careers'
										aria-current='page'
										className='button-dark w-button w--current'
									>
										Explore Opportunities at Backtify
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<section id='values' className='section-content big-padding'>
				<div className='container-large'>
					<section className='title-wrapper-centre'>
						<h3 className='h3-title'>At Backtify we celebrate:</h3>
					</section>
					<div className='w-layout-grid grid-timeline'>
						<div className='card-timeline'>
							<h4 className='h5-heading white'>Compassion</h4>
							<p className='white'>We are kind and we operate with the best intentions.</p>
						</div>
						<div className='card-timeline'>
							<h4 className='h5-heading white'>Composure</h4>
							<p className='white'>
								We instill calm, inspire confidence, and thrive under pressure.{' '}
							</p>
						</div>
						<div className='card-timeline'>
							<h4 className='h5-heading white'>Conscientiousness</h4>
							<p className='white'>We bring sound judgment and discipline to every endeavor.</p>
						</div>
						<div className='card-timeline'>
							<h4 className='h5-heading white'>Communication</h4>
							<p className='white'>We capture, validate, and meet expectations.</p>
						</div>
						<div className='card-timeline'>
							<h4 className='h5-heading white'>Curiosity</h4>
							<p className='white'>We embrace a mindset of continuous learning.</p>
						</div>
						<div className='card-timeline'>
							<h4 className='h5-heading white'>Community</h4>
							<p className='white'>
								We work independently, but we are not alone. Knowledge-sharing binds us together.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section id='open-positions' className='section-2 homepage-hero-section wf-section'>
				<div className='container-2'>
					<h2 className='homepage-heading'>Build you Careers at Backtify</h2>
				</div>
			</section>

			<section className='section-2 wf-section'>
				<div className='container-2'>
					<a href='/contact' className='button-primary what-we-do w-button bl-padding'>
						<strong>Explore Opportunities at Backtify</strong>
					</a>
				</div>
			</section>
		</>
	);
};

export default CareersScreen;
