import React from 'react';

const Header = () => {
	return (
		<>
			<div
				data-collapse='medium'
				data-animation='default'
				data-duration='400'
				data-easing='ease'
				data-easing2='ease'
				role='banner'
				className='navigation w-nav'
			>
				<div className='container-navigation'>
					<div className='navigation-menu'>
						<a
							href='/index'
							aria-current='page'
							className='brand w-nav-brand w--current'
							aria-label='home'
						>
							<img src='images/logo-site.png' alt='' className='brand-logo' />

							<span>
								Backtify
								<p>Consulting</p>
							</span>
						</a>
						<nav role='navigation' className='nav-menu w-nav-menu'>
							<a href='/what-we-do' id='header-nav-link' className='header-nav-link w-nav-link'>
								What We Do
							</a>
							<a href='/who-we-are' className='header-nav-link w-nav-link'>
								Who We Are
							</a>
							<a href='/consumer' className='header-nav-link w-nav-link'>
								Who We Work With
							</a>
							<a href='/careers' className='header-nav-link w-nav-link'>
								Join Our Team
							</a>
						</nav>
					</div>
					<div className='navigation-button-wrap'>
						<a href='/contact' className='navigation-button w-button'>
							Let's Talk
						</a>
						<div
							className='menu-button w-nav-button'
							// style='-webkit-user-select: text;'
							aria-label='menu'
							role='button'
							tabIndex='0'
							aria-controls='w-nav-overlay-0'
							aria-haspopup='menu'
							aria-expanded='false'
						>
							<div className='w-icon-nav-menu'></div>
						</div>
					</div>
				</div>
				<div className='w-nav-overlay' data-wf-ignore='' id='w-nav-overlay-0'></div>
			</div>
		</>
	);
};

export default Header;
