import React, { useState } from 'react';
import emailjs from '@emailjs/browser'

const MyForm = () => {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		location: '',
		numberOfEmployees: '',
		revenueRadio: '',
		services: {
			boardReadiness: false,
			bookkeeping: false,
			financeAccounting: false,
			fundraisingStrategy: false,
			marketingPR: false,
			humanResources: false,
			recruiting: false,
		},
		message: '',
	});

	const [formStatus, setFormStatus] = useState(null);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;

		if (type === 'checkbox') {
			setFormData({
				...formData,
				services: {
					...formData.services,
					[name]: checked,
				},
			});
		} else {
			setFormData({
				...formData,
				[name]: value,
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
		await emailjs.send(
        'service_iznlzhb',
        'template_8w3tsve',
        { ...formData, ...formData.services, email: 'anastasiatrocenko75@gmail.com', mailbox: formData.email },
        {
          publicKey: 'H_B4XsNSO-4VoUSsQ',
        },
      )
			setFormStatus('success');
		} catch (error) {
			setFormStatus('error');
		}
	};

	return (
		<>
			<form id='wf-form-Let-s-Talk-Form' onSubmit={handleSubmit}>
				<div className='input-wrapper'>
					<label htmlFor='First-Name' className='field-label'>
						First Name
					</label>
					<input
						type='text'
						className='input-field w-input'
						maxLength='256'
						name='firstName'
						placeholder='First Name'
						id='First-Name'
						required
						onChange={handleChange}
					/>
				</div>
				<div className='input-wrapper'>
					<label htmlFor='Last-Name' className='field-label'>
						Last Name
					</label>
					<input
						type='text'
						className='input-field w-input'
						maxLength='256'
						name='lastName'
						placeholder='Last Name'
						id='Last-Name'
						required
						onChange={handleChange}
					/>
				</div>
				<div className='input-wrapper'>
					<label htmlFor='Email' className='field-label'>
						Email
					</label>
					<input
						type='email'
						className='input-field w-input'
						maxLength='256'
						name='email'
						placeholder='Email Address'
						id='Email'
						required
						onChange={handleChange}
					/>
				</div>
				<div className='input-wrapper'>
					<label htmlFor='Phone' className='field-label'>
						Phone
					</label>
					<input
						type='tel'
						className='input-field w-input'
						maxLength='256'
						name='phone'
						placeholder='Enter Phone'
						id='Phone'
						required
						onChange={handleChange}
					/>
				</div>
				<div className='input-wrapper'>
					<label htmlFor='Location' className='field-label'>
						Where is your company located?
					</label>
					<input
						type='text'
						className='input-field w-input'
						maxLength='256'
						name='location'
						placeholder='City, State'
						id='Location'
						required
						onChange={handleChange}
					/>
				</div>
				<div className='input-wrapper'>
					<label htmlFor='Number-of-Employees' className='field-label'>
						How many employees do you have?
					</label>
					<select
						id='Number-of-Employees'
						name='numberOfEmployees'
						className='w-select'
						onChange={handleChange}
					>
						<option value=''>Select one...</option>
						<option value='0-10'>0-10</option>
						<option value='10-19'>10-19</option>
						<option value='20-50'>20-50</option>
						<option value='50+'>50+</option>
					</select>
				</div>
				<div className='input-wrapper'>
					<label htmlFor='Revenue-Radio' className='field-label'>
						Are you generating revenue?
					</label>
					<label className='w-radio'>
						<input
							type='radio'
							name='revenueRadio'
							id='Yes'
							value='Yes'
							className='w-form-formradioinput w-radio-input'
							onChange={handleChange}
						/>
						<span className='w-form-label' htmlFor='Yes'>
							Yes
						</span>
					</label>
					<label className='w-radio'>
						<input
							type='radio'
							name='revenueRadio'
							id='No'
							value='No'
							className='w-form-formradioinput w-radio-input'
							onChange={handleChange}
						/>
						<span className='w-form-label' htmlFor='No'>
							No
						</span>
					</label>
				</div>
				<div className='input-wrapper'>
					<label htmlFor='How-can-we-help' className='field-label'>
						How can we help?
					</label>
					<label className='w-checkbox'>
						<input
							type='checkbox'
							name='boardReadiness'
							id='Board-Readiness'
							className='w-checkbox-input'
							onChange={handleChange}
						/>
						<span className='w-form-label' htmlFor='Board-Readiness'>
							Board Readiness
						</span>
					</label>
					<label className='w-checkbox checkbox-field'>
						<input
							type='checkbox'
							name='bookkeeping'
							id='Bookkeeping'
							className='w-checkbox-input'
							onChange={handleChange}
						/>
						<span className='w-form-label' htmlFor='Bookkeeping'>
							Bookkeeping
						</span>
					</label>
					<label className='w-checkbox checkbox-field'>
						<input
							type='checkbox'
							name='financeAccounting'
							id='Finance-Accounting'
							className='w-checkbox-input'
							onChange={handleChange}
						/>
						<span className='w-form-label' htmlFor='Finance-Accounting'>
							Finance &amp; Accounting
						</span>
					</label>
					<label className='w-checkbox checkbox-field'>
						<input
							type='checkbox'
							name='fundraisingStrategy'
							id='Fundraising-Strategy'
							className='w-checkbox-input'
							onChange={handleChange}
						/>
						<span className='w-form-label' htmlFor='Fundraising-Strategy'>
							Fundraising Strategy
						</span>
					</label>
					<label className='w-checkbox checkbox-field'>
						<input
							type='checkbox'
							name='marketingPR'
							id='Marketing-PR'
							className='w-checkbox-input'
							onChange={handleChange}
						/>
						<span className='w-form-label' htmlFor='Marketing-PR'>
							Growth Strategy
						</span>
					</label>
					<label className='w-checkbox checkbox-field'>
						<input
							type='checkbox'
							name='humanResources'
							id='Human-Resources'
							className='w-checkbox-input'
							onChange={handleChange}
						/>
						<span className='w-form-label' htmlFor='Human-Resources'>
							People Operations (HR)
						</span>
					</label>
					<label className='w-checkbox checkbox-field'>
						<input
							type='checkbox'
							name='recruiting'
							id='Recruiting'
							className='w-checkbox-input checkbox'
							onChange={handleChange}
						/>
						<span className='w-form-label' htmlFor='Recruiting'>
							Recruiting
						</span>
					</label>
				</div>
				<div className='input-wrapper'>
					<label htmlFor='Message' className='field-label'>
						Your message
					</label>
					<textarea
						required
						id='Message'
						name='message'
						maxLength='5000'
						placeholder='Please note any specific services you need (e.g. Bookkeeping, CFO, CPO, CMO, Roadmap to Funding, Board prep)'
						className='input-field-large w-input'
						onChange={handleChange}
					></textarea>
				</div>
				<input
					type='submit'
					value='Send Message'
					data-wait='Please wait...'
					className='submit-button-dark-full w-button'
				/>
			</form>
			<div
				className='w-form-done'
				tabIndex='-1'
				role='region'
				aria-label="Let's Talk Form success"
				style={{ display: formStatus === 'success' ? 'block' : 'none' }}
			>
				<div>Thank you! Your submission has been received!</div>
			</div>

			<div
				className='w-form-fail'
				tabIndex='-1'
				role='region'
				aria-label="Let's Talk Form failure"
				style={{ display: formStatus === 'error' ? 'block' : 'none' }}
			>
				<div>Oops! Something went wrong while submitting the form.</div>
			</div>
		</>
	);
};

export default MyForm;
