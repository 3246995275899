import React from 'react';

const Footer = () => {
	return (
		<footer className='section-footer'>
			<div className='container'>
				<div className='w-layout-grid footer-grid'>
					<div className='footer-link-column'>
						<div className='footer-content'>
							<h4 className='footer-title'>
								Peace of mind for founders.
								<span className='underline'></span>
							</h4>
							<p className='footer-paragraph'>Backtify (c) 2024 All rights reserved.</p>
						</div>
					</div>
					<div className='footer-link-column'>
						<div className='footer-link-title'>Services</div>
						<a href='/what-we-do#finance-accounting' className='footer-link'>
							Financial Services
						</a>
						<a href='/what-we-do#strategic-growth' className='footer-link'>
							Growth Strategy Services
						</a>
						<a href='/what-we-do#human-resources' className='footer-link'>
							Human Resources Services
						</a>
					</div>
					<div className='footer-link-column'>
						<div className='footer-link-title'>Connect With Us</div>
						<a href='/contact' className='footer-link'>
							Contact Us
						</a>
					</div>
					<div className='footer-link-column'>
						<div className='footer-link-title'>Information</div>
						<a href='/privacy-policy' className='footer-link'>
							Privacy Policy
						</a>
						<a href='/terms' className='footer-link'>
							Terms &amp; Conditions
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
