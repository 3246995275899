import React from 'react';

const WhoWeAreScreen = () => {
	return (
		<>
			<div id='hero-overlay' className='section-hero-who-we-are wf-section'>
				<h1 className='heading-5'>We bring peace of mind to founders.</h1>
				<div className='centered-container w-container'>
					<div className='div-block-82'></div>
				</div>
			</div>

			<div className='section-client'>
				<div className='container-large'>
					<div className='client-block'>
						<div className='w-layout-grid logo-grid-horizontal hide'>
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='rigid logo 2' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
						</div>
					</div>
				</div>
			</div>

			<div className='section-team'>
				<div className='container-large'>
					<div className='title-wrapper-centre'>
						<h2>We would love to meet you. </h2>
					</div>
					<div className='team-list-wrapper w-dyn-list'>
						<div role='list' className='team-collection w-dyn-items'>
							<div
								id='w-node-aa09aae6-d399-82bd-8f0a-db205c3ca9b3-9eb96c18'
								role='listitem'
								className='w-dyn-item'
							>
								<a href='#' className='card-team w-inline-block'>
									<div className='team-avatar-large'>
										<img
											height=''
											loading='lazy'
											src='images/Ingemar-Str%C3%B6mberg.svg'
											alt='Practice Manager'
											className='image-cover'
										/>
									</div>
									<div className='team-detail-wrapper'>
										<div className='team-name'>Vincent Backtify</div>
										<div className='team-role'>CEO</div>
									</div>
								</a>
							</div>
							<div
								id='w-node-aa09aae6-d399-82bd-8f0a-db205c3ca9b3-9eb96c18'
								role='listitem'
								className='w-dyn-item'
							>
								<a href='#' className='card-team w-inline-block'>
									<div className='team-avatar-large'>
										<img
											height=''
											loading='lazy'
											src='images/Agneta-Sandberg.svg'
											alt='COO'
											className='image-cover'
										/>
									</div>
									<div className='team-detail-wrapper'>
										<div className='team-name'>Agneta Sandberg</div>
										<div className='team-role'>COO</div>
									</div>
								</a>
							</div>
							<div
								id='w-node-aa09aae6-d399-82bd-8f0a-db205c3ca9b3-9eb96c18'
								role='listitem'
								className='w-dyn-item'
							>
								<a href='#' className='card-team w-inline-block'>
									<div className='team-avatar-large'>
										<img
											height=''
											loading='lazy'
											src='images/Hanna-Vikstr%C3%B6m.svg'
											alt='CXO'
											className='image-cover'
										/>
									</div>
									<div className='team-detail-wrapper'>
										<div className='team-name'>Hanna Vikström</div>
										<div className='team-role'>CXO</div>
									</div>
								</a>
							</div>
							<div
								id='w-node-aa09aae6-d399-82bd-8f0a-db205c3ca9b3-9eb96c18'
								role='listitem'
								className='w-dyn-item'
							>
								<a href='#' className='card-team w-inline-block'>
									<div className='team-avatar-large'>
										<img
											height=''
											loading='lazy'
											src='images/Helena-Ivarsson.svg'
											alt='CGO'
											className='image-cover'
										/>
									</div>
									<div className='team-detail-wrapper'>
										<div className='team-name'>Helena Ivarsson</div>
										<div className='team-role'>CGO</div>
									</div>
								</a>
							</div>
							<div
								id='w-node-aa09aae6-d399-82bd-8f0a-db205c3ca9b3-9eb96c18'
								role='listitem'
								className='w-dyn-item'
							>
								<a href='#' className='card-team w-inline-block'>
									<div className='team-avatar-large'>
										<img
											height=''
											loading='lazy'
											src='images/Sofia-Ek.svg'
											alt='Employee Experience'
											className='image-cover'
										/>
									</div>
									<div className='team-detail-wrapper'>
										<div className='team-name'>Sofia Ek�&nbsp;</div>
										<div className='team-role'>Employee Experience</div>
									</div>
								</a>
							</div>
							<div
								id='w-node-aa09aae6-d399-82bd-8f0a-db205c3ca9b3-9eb96c18'
								role='listitem'
								className='w-dyn-item'
							>
								<a href='#' className='card-team w-inline-block'>
									<div className='team-avatar-large'>
										<img
											height=''
											loading='lazy'
											src='images/Sara-Gustafsson.svg'
											alt='Practice Manager'
											className='image-cover'
										/>
									</div>
									<div className='team-detail-wrapper'>
										<div className='team-name'>Sara Gustafsson</div>
										<div className='team-role'>Practice Manager</div>
									</div>
								</a>
							</div>
							<div
								id='w-node-aa09aae6-d399-82bd-8f0a-db205c3ca9b3-9eb96c18'
								role='listitem'
								className='w-dyn-item'
							>
								<a href='#' className='card-team w-inline-block'>
									<div className='team-avatar-large'>
										<img
											height=''
											loading='lazy'
											src='images/Malin-Jonasson.svg'
											alt='CEO'
											className='image-cover'
										/>
									</div>
									<div className='team-detail-wrapper'>
										<div className='team-name'>Malin Jonasson</div>
										<div className='team-role'>Practice Manager</div>
									</div>
								</a>
							</div>
							<div
								id='w-node-aa09aae6-d399-82bd-8f0a-db205c3ca9b3-9eb96c18'
								role='listitem'
								className='w-dyn-item'
							>
								<a href='#' className='card-team w-inline-block'>
									<div className='team-avatar-large'>
										<img
											height=''
											loading='lazy'
											src='images/Tobias-Lund.svg'
											alt='Practice Manager'
											className='image-cover'
										/>
									</div>
									<div className='team-detail-wrapper'>
										<div className='team-name'>Tobias Lund</div>
										<div className='team-role'>Practice Manager</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='section-content full-bleed-cta-core-values'>
				<div className='container-large'>
					<div className='div-block-84'>
						<div>
							<h2 className='heading-12'>What We Believe In</h2>
							<div className='clip'>
								<a href='/careers#values' className='button-primary w-button'>
									Our core values
								</a>
							</div>
						</div>
						<div></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default WhoWeAreScreen;
