import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MainLayout from './layouts/MainLayout/MainLayout';
import HomeScreen from './screens/home/HomeScreen';
import ContactScreen from './screens/contact/ContactScreen';
import CareersScreen from './screens/careers/CareersScreen';
import ConsumerScreen from './screens/consumer/ConsumerScreen';
import WhatWeDoScreen from './screens/what-we-do/WhatWeDoScreen';
import WhoWeAreScreen from './screens/who-we-are/WhoWeAreScreen';
import PrivacyPolicyScreen from './screens/privacy-policy/PrivacyPolicyScreen';
import TermsScreen from './screens/terms/TermsScreen';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<MainLayout />}>
					<Route path='/' element={<HomeScreen />} />
					<Route path='/index' element={<HomeScreen />} />
					<Route path='/contact' element={<ContactScreen />} />
					<Route path='/careers' element={<CareersScreen />} />
					<Route path='/consumer' element={<ConsumerScreen />} />
					<Route path='/what-we-do' element={<WhatWeDoScreen />} />
					<Route path='/who-we-are' element={<WhoWeAreScreen />} />
					<Route path='/privacy-policy' element={<PrivacyPolicyScreen />} />
					<Route path='/terms' element={<TermsScreen />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
