import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

const MainLayout = () => {
	return (
		<>
			<Header />
			<Suspense fallback='...'>
				<Outlet />
			</Suspense>
			<Footer />
		</>
	);
};

export default MainLayout;
