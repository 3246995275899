import React from 'react';

const WhatWeDoScreen = () => {
	return (
		<>
			<div id='hero-overlay' className='section-hero-what-we-do wf-section'>
				<div className='centered-container w-container'>
					<h1 className='heading-5'>
						Your trusted thought partner through each stage of your startup's journey.
					</h1>
					<div className='div-block-82'>
						<a href='/contact' className='button-primary w-button'>
							Book your free consultation
						</a>
					</div>
				</div>
			</div>

			<div className='section-client'>
				<div className='container-large'>
					<div className='client-block'>
						<div className='w-layout-grid logo-grid-horizontal hide'>
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='rigid logo 2' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
							<img src='#' loading='lazy' alt='' className='image-3' />
						</div>
					</div>
				</div>
			</div>

			<div className='section-content big-padding'>
				<div className='container'>
					<div>
						<div>
							<h2 className='heading-11'>Right service. Right talent. Right time.</h2>
						</div>
						<div>
							<div className='w-layout-grid grid-timeline'>
								<div className='card-timeline-2'>
									<div>
										<img
											src='images/img-card-one.jpeg'
											loading='lazy'
											srcset='images/finance-GettyImages-1315371152-p-500.jpeg 500w, images/finance-GettyImages-1315371152-p-800.jpeg 800w, images/finance-GettyImages-1315371152-p-1080.jpeg 1080w, images/finance-GettyImages-1315371152-p-1600.jpeg 1600w, images/finance-GettyImages-1315371152-p-1772.jpeg 1772w'
											alt='accounting &amp; finance professional using calculator'
											sizes='90vw'
											className='image-6'
										/>
									</div>
									<div className='div-block-94'>
										<h3 className='h5-heading center'>
											<strong>Financial &amp; Accounting Services</strong>
										</h3>
										<a
											href='/what-we-do#finance-accounting'
											className='button-primary margin-top w-button'
										>
											Learn More
										</a>
									</div>
								</div>
								<div className='card-timeline-2'>
									<img
										src='images/img-card-two.jpeg'
										loading='lazy'
										srcset='images/Growth-GettyImages-639428672-p-500.jpeg 500w, images/Growth-GettyImages-639428672-p-800.jpeg 800w, images/Growth-GettyImages-639428672-p-1080.jpeg 1080w, images/Growth-GettyImages-639428672-p-1600.jpeg 1600w, images/Growth-GettyImages-639428672-p-1755.jpg 1755w'
										alt='strategic growth woman planning'
										sizes='90vw'
										className='image-6'
									/>{' '}
									<div className='div-block-94'>
										<h3 className='h5-heading'>
											<strong>Financial Consulting &amp; Accounting Services</strong>
										</h3>
										<a
											href='/what-we-do#strategic-growth'
											className='button-primary margin-top w-button'
										>
											Learn More
										</a>
									</div>
								</div>
								<div className='card-timeline-2'>
									<div>
										<img
											src='images/logoname-woman-compressed.jpg'
											loading='lazy'
											srcset='images/logoname-woman-compressed-p-500.jpeg 500w, images/logoname-woman-compressed-p-1773.jpg 1773w'
											alt='woman in human resources'
											sizes='90vw'
											className='image-6'
										/>
									</div>
									<div className='div-block-94'>
										<h3 className='h5-heading'>Human Resources</h3>
										<a
											href='/what-we-do#human-resources'
											className='button-primary margin-top w-button'
										>
											Learn More
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className='div-block-83'>
							<div id='w-node-c26fa29f-28ee-82b4-8eeb-51007f5d9b42-24b96c1c' className='stages'>
								<div className='body-normal-bold'>
									<strong>Early Stage</strong>
								</div>
								<div className='body-large no-margin-top center-align'>
									You are poised to
									<strong>Launch</strong>. You are building the foundational elements and
									infrastructure needed to scale.
								</div>
								<a href='#' className='button-primary no-min-width w-button'>
									How We Can Help
								</a>
							</div>
							<div id='w-node-d4c65291-b659-3ebc-869f-767c58be4537-24b96c1c' className='stages'>
								<div className='body-normal-bold'>
									<strong>Venture Funded</strong>
								</div>
								<div className='body-large no-margin-top center-align'>
									Time to
									<strong>grow</strong>. You secured financing and need to achieve key milestones.
								</div>
								<a href='#' className='button-primary w-button'>
									How We Can Help
								</a>
							</div>
							<div className='stages'>
								<div className='body-normal-bold'>
									<strong>Late Stage</strong>
								</div>
								<div className='body-large no-margin-top center-align'>
									You are building defensible systems, optimizing your team, &nbsp;and focusing on
									your killer KPI's and exit.
								</div>
								<a href='#' className='button-primary w-button'>
									How We Can Help
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<section
				id='finance-accounting'
				className='section-content service-banner-financial-services'
			>
				<div className='container-large'>
					<div className='div-block-84'>
						<div>
							<h2 className='heading-12'>Accounting &amp;&nbsp;Finance</h2>
							<div className='body-normal text-color-white'>
								Powering actionable business insights through sound financial practices
							</div>
							<div className='clip'>
								<a href='/contact' className='button-primary what-we-do w-button'>
									<strong>Book a free consultation with our CXO</strong>
								</a>
							</div>
						</div>
						<div>
							<div className='quote-wrapper-service-banner'>
								<img
									src='images/Quote-Icon.svg'
									loading='lazy'
									alt=''
									className='quote-image invert'
								/>
								<h2 className='heading-13'>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nec gravida purus.
									Ut justo arcu, eleifend id congue ut, suscipit aliquet neque.
								</h2>
								<div className='testimonial-name-2'>Client Name</div>
								<img
									src='images/Quote-Icon.svg'
									loading='lazy'
									alt=''
									className='quote-image bottom-hide'
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div className='section-content big-padding'>
				<div className='container-large'>
					<div className='div-block-93'>
						<h3>Accounting &amp; Finance Services:</h3>
					</div>
					<div className='w-layout-grid grid-price-columns'>
						<div className='price-card'>
							<div className='price-wrap-stage'>
								<div className='stage-name'>Early-Stage </div>
							</div>
							<div className='price-wrap-year'>
								<div className='price-tag'>$129</div>
								<div className='date-text'>/ Year</div>
							</div>
							<p className='price-details'>
								<strong>Daily processes and compliance practices as a foundation for growth</strong>
								<br />‍<br />
							</p>
							<div className='w-layout-grid feature-grid'>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Chart of accounts</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Accounts payable &amp; receivables</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Payroll processing</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Reliable monthly close</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Internal controls</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>State and local tax compliance</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Sales tax risk assessment</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Avalara implementation</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Multi-currency considerations</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Accounting clean-up</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Cap table reconciliation</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Scaleable tech stack</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Bookkeeping Service</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Finance Consulting Services</div>
								</div>
							</div>
						</div>
						<div className='price-card'>
							<div className='price-wrap-stage'>
								<div className='stage-name'>Funded</div>
							</div>
							<div className='price-wrap-year'>
								<div className='price-tag'>$129</div>
								<div className='date-text'>/ Year</div>
							</div>
							<p className='price-details'>
								<strong>
									Finance &amp;&nbsp;Accounting reports as a decision-making tool
									<br />‍
								</strong>
								<br />
							</p>
							<div className='w-layout-grid feature-grid'>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Burn rate and cash-out analysis</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Financial reporting framework</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Board meeting preparation and support</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Revenue recognition (ASC 606)</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Cost of goods sold analysis</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Lease accounting treatment</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>R &amp; D tax credit</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>
										Coordination with CPA firm on tax/GAAP treatment
									</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>409a valuation coordination</div>
								</div>
							</div>
						</div>
						<div id='w-node-_8d98581e-ba07-d860-173a-0009f3958a36-24b96c1c' className='price-card'>
							<div className='price-wrap-stage'>
								<div className='stage-name'>Late-Stage</div>
							</div>
							<div className='price-wrap-year'>
								<div className='price-tag'>$129</div>
								<div className='date-text'>/ Year</div>
							</div>
							<p className='price-details'>
								<strong>
									Planning and systems for scale
									<br />‍
								</strong>
							</p>
							<div className='w-layout-grid feature-grid'>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Audit readiness</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Data room for due diligence</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Financial planning and forecasting</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Multi entity consolidation</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Scalable tech stack</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>
										Online solutions and payment engine integration
									</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>Baseline pay for founders</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>
										Stock compensation
										<br />
									</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>
										Finance Consulting
										<br />
									</div>
								</div>
								<div className='feature-wrap'>
									<img src='images/All-Icons-24.svg' alt='' className='check' />
									<div className='feature-text'>
										Accounting Consulting
										<br />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<a href='/contact' className='button-primary what-we-do w-button'>
					<strong>Book a free consultation</strong>
				</a>
			</div>
		</>
	);
};

export default WhatWeDoScreen;
