import React from 'react';

const PrivacyPolicyScreen = () => {
	return (
		<>
			<div className='template-header'>
				<div className='container'>
					<div className='title-wrapper-centre'>
						<h1>Privacy Policy</h1>
					</div>
				</div>
			</div>

			<div className='template-section'>
				<div className='container-small'>
					<div className='template-detail-block'>
						<div>
							This Privacy Policy applies to the website(s) and mobile applications (together, the
							"Sites") provided by Backtify Group, and/or the other companies of the Backtify
							Consulting Group ("Backtify", “we”, “us”) who act as data controllers with respect to the
							personal data you share with Backtify under this Policy.
							<br />
							The Sites include www.Backtify.org and additional websites or mobile applications
							produced and managed by Backtify.
							<br />
							Backtify knows that your privacy is important to you and takes user privacy very
							seriously. We are committed to protecting your personal data (i.e. any information you
							provide to us from which you can be identified) in accordance with this Privacy
							Policy.
							<br />
							Backtify reserves the right, at its sole discretion, to alter and update this Privacy
							Policy from time to time. We therefore invite you to review the current version of the
							Privacy Policy each time you return to our Sites.
							<br />
							<br />
							<strong> INFORMATION WE MAY COLLECT FROM YOU</strong>
							<br /> We will collect and process the following data about you:
							<br />·<em>Information you give to us.</em>
							This is information about you that you give us by filling in forms on our site or by
							corresponding with us by phone, e-mail or otherwise. It includes information you
							provide when you respond to job postings on our site, or request information about our
							services, and when you report a problem with our site. The information you give us may
							include your name, address, e-mail address and phone number, and other information
							that you voluntarily provide.
							<br />·<em>Information we collect about you.</em> With regard to each of your visits
							to our site we will automatically collect the following information:
							<br />
							·technical information, including the domain and host name from which you access the
							Internet, the Internet protocol (IP) address used to connect your computer to the
							Internet, browser type and version, time zone and country setting, browser plug-in
							types and versions, operating system and platform;
							<br />
							·information about your visit, including the full Uniform Resource Locators (URL),
							clickstream to, through and from our site (including date and time), services you
							viewed or searched for, page response times, download errors, length of visits to
							certain pages, page interaction information (such as scrolling, clicks, and
							mouse-overs), and methods used to browse away from the page.
							<br />·<em>Information we receive from other sources.</em> We are working closely with
							third parties (including, for example, marketing services) that may provide us with
							additional information about you.
							<br />
							<br />
							<strong>Modalis Course Privacy</strong>
							<br />
							Our Modalis platform monitors time, click, and responses in an effort to build a solid
							picture of participant performance. This enables big data-style queries of trends and
							various aggregates to give unique business insight to our customers.By default, the
							course database never stores or even handles any Personally Identifiable Information
							(PII). &nbsp;The database allows customers to distinguish between users without
							identifying them because clients use a one-way hash to irretrievably scramble their IP
							address into what we refer to as an anonymous ID. &nbsp;It is this anonymous ID that
							the course servers receive from the client that permits distinguishing between, but
							not identification of, users.Information is anonymized such that it is possible to
							understand user behavior both in aggregate and individually, but, subject to the
							caveat above, information about activity is not attributable to specific
							individuals.The course does store answers typed in by users and the authorized user
							identifier handed to it by a Learning Management System (LMS) if in use. &nbsp;Unless
							specifically requested of us by the customer, we avoid requesting PII-related
							information from either the course participant or from the LMS.
							<br />
							<br />
							<strong>COOKIES</strong>
							<br />
							Our site uses cookies to distinguish you from other users of our site. This helps us
							to provide you with a good experience when you browse our site and also allows us to
							improve our site.
							<br />
							<br />‍<strong>USES MADE OF THE INFORMATION</strong>
							<br />
							We use information held about you in the following ways:
							<br />·<em>Information you give to us</em>. We will use this information:
							<br />
							·to carry out our obligations arising from any contracts entered into between you and
							us and to provide you with the information, products and services that you request
							from us;
							<br />
							·to provide you with information about other goods and services we offer that are
							similar to those that you have already purchased or inquired about;
							<br />
							·to provide you, or permit selected third parties to provide you, with information
							about goods or services we feel may interest you. We will only contact you by
							electronic means (e-mail or SMS) with information about goods and services similar to
							those which were the subject of a previous sale or negotiations of a sale to you, or
							where you have consented to this, or where we can otherwise lawfully do so.
							<br />
							·to notify you about changes to our service;
							<br />
							·to ensure that content from our site is presented in the most effective manner for
							you and for your computer. <br />·<em>Information we collect about you.</em> We will
							use this information:
							<br />
							·to administer our site and for internal operations, including troubleshooting, data
							analysis, testing, research, statistical and survey purposes;
							<br />· to improve our site to ensure that content is presented in the most effective
							manner for you and for your computer;· as part of our efforts to keep our site safe
							and secure;
							<br />· to measure or understand the effectiveness of advertising we serve to you and
							others, and to deliver relevant advertising to you;
							<br />· to make suggestions and recommendations to you and other users of our site
							about goods or services that may interest you or them.
							<br />·
							<em>
								Information we receive from other sources. <br />‍
							</em>
							· We will combine this information with information you give to us and information we
							collect about you. We will use this information and the combined information for the
							purposes set out above (depending on the types of information we receive).
							<strong>
								{' '}
								<br />
								<br />
								DISCLOSURE OF YOUR INFORMATION
								<br />‍
							</strong>
							You agree that we have the right to share your personal information with:
							<br />· Any member of our group, which means our subsidiaries.
							<br />· Selected third parties including:
							<br />· service providers with which we have an agreement. These third-party service
							providers assist us in assessing our information security controls, maintaining our
							site, analyzing our site traffic, and in providing services and information to us and
							to users of our site;
							<br />· advertisers and advertising networks that require the data to select and serve
							relevant advertisements to you and others. We do not disclose information about
							identifiable individuals to our advertisers and advertising networks, but we may
							provide them with aggregate information about our users. We may also use such
							aggregate information to help advertisers reach the kind of audience they want to
							target;
							<br />· analytics and search engine providers that assist us in the improvement and
							optimization of our site. We will also disclose your personal information to third
							parties:
							<br />· In the event that we sell or buy any business or assets, in which case we will
							disclose your personal data to the prospective seller or buyer of such business or
							assets.
							<br />· Backtify or substantially all of the assets of any of the foregoing, is acquired
							by a third party, in which case personal data held by it about its customers will be
							one of the transferred assets.
							<br />· If we are under a duty to disclose or share your personal data in order to
							comply with any legal obligation, or in order to enforce or apply our
							<a href='#' target='_blank'>
								<strong className='bold-text-6'>Terms of Use</strong>
							</a>{' '}
							and other agreements; or to protect the rights, property, or safety of Backtify, or its
							subsidiaries, our customers, or others.If you divulge information in a public forum
							offered on our site such as a chat room, message board and/or news group, this will be
							considered public information and may be disclosed and used by third parties.
							<br />
							<br />‍
							<strong>
								WHERE WE STORE YOUR PERSONAL DATA
								<br />‍
							</strong>
							The data that we collect from you will be transferred to, and stored at, a destination
							outside the European Economic Area (“EEA”). It will also be processed by staff
							operating outside the EEA who work for us or for one of our suppliers. By submitting
							your personal data, you agree to this transfer, storing or processing. We will take
							all steps reasonably necessary to ensure that your data is treated securely and in
							accordance with this privacy policy.All information you provide to us is stored on our
							secure servers or at our third party data centers.Unfortunately, the transmission of
							information via the internet is not completely secure. Although we will do our best to
							protect your personal data, we cannot guarantee the security of your data transmitted
							to our site; any transmission is at your own risk. Once we have received your
							information, we will use strict procedures and security features to try to prevent
							unauthorized access.
							<br />
							<br />‍
							<strong>
								YOUR RIGHTS
								<br />‍
							</strong>
							Depending on where you are located, you may have the right, under law, to ask us not
							to process your personal data for marketing purposes. Where you have the right, under
							law, to prevent such processing, you can exercise your right by contacting us. Our
							site may, from time to time, contain links to and from the websites of our partner
							networks, advertisers and affiliates. If you follow a link to any of these websites,
							please note that these websites have their own privacy policies and that we do not
							accept any responsibility or liability for these policies. Please check these policies
							before you submit any personal data to these websites.
							<br />
							<br />‍
							<strong>
								ACCESS TO INFORMATION
								<br />‍
							</strong>
							Depending on where you are located, you may have the right, under law, to access
							information held about you. Your right of access can be exercised in accordance with
							applicable law, which might be the Act. Any access request may be subject to a fee as
							may be notified to you from time to time, to meet our costs in providing you with
							details of the information we hold about you. Any request should be made in writing,
							and the best way for you to do so is to contact us at the address stated below. As we
							must be able to identify the person making the request, and because a fee may be due,
							we request that you confirm any request made by you via different means by contacting
							us at the address stated below. Due to technological constraints and/or information
							security considerations, it may be inappropriate to use social media to supply
							information in response to any request by you for access to information, and so please
							provide an alternative delivery address for our response.
							<br />
							<br />‍
							<strong>
								CHANGES TO OUR PRIVACY POLICY
								<br />‍
							</strong>
							We may modify this privacy policy from time to time. Any changes we make to our
							privacy policy in the future will be posted on this page. Please check back frequently
							to see any updates or changes to our privacy policy.
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrivacyPolicyScreen;
