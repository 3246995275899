import React from 'react';

const HomeScreen = () => {
	return (
		<>
			<div id='hero-overlay' className='section-hero-home wf-section'>
				<div className='div-block-95 w-clearfix'>
					<h1 className='heading-5'>Thriving in the innovation economy is complex</h1>
					<p className='body-large white no-margin'>
						Backtify delivers startups clarity in the face of complexity
					</p>
					<div className='div-block-82'>
						<a href='/what-we-do' className='button-primary w-button'>
							Our Services
						</a>
					</div>
				</div>
			</div>

			<div className='section-client'>
				<div className='container-large'>
					<div className='client-block'>
						<div className='w-layout-grid logo-grid-horizontal hide'>
							<img src='' loading='lazy' alt='' className='image-3' />
							<img src='' loading='lazy' alt='rigid logo 2' className='image-3' />
							<img src='' loading='lazy' alt='' className='image-3' />
							<img src='' loading='lazy' alt='' className='image-3' />
							<img src='' loading='lazy' alt='' className='image-3' />
							<img src='' loading='lazy' alt='' className='image-3' />
						</div>
					</div>
				</div>
			</div>

			<div className='section-content'>
				<div className='section-content-2'>
					<div className='container'>
						<h2 className='company-quote'>
							Fractional{' '}
							<a href='#'>
								<span className='brand-span'>Accounting {' '}Finance, {' '}</span>
							</a>
							<a href='#'>
								<span className='brand-span'>Strategic Growth, and </span>
							</a>
							<a href='#'>
								<span className='brand-span'>HR</span> {' '}
							</a>
							services for each stage of your startup's journey. &nbsp;
						</h2>
						<div className='body-large no-top-margin'>
							<strong className='bold-text'>
								You need mission-critical support, but don't need full-time hires.{' '}
							</strong>
						</div>
					</div>
				</div>
				<div className='container-large big-padding'>
					<div className='w-layout-grid grid-content margin-top'>
						<div className='background-video-3 w-background-video w-background-video-atom background-video-one'></div>
						<div
							id='w-node-ca5a7e9b-ce4f-c6d0-425f-18e0af079852-ccb96c59'
							className='content-wrapper'
						>
							<h3>
								Right resource.
								<br />
								Right rate. <br />
								Right time.
							</h3>
							<p className='body-large'>
								Backtify Founder Consulting offers business and financial consulting, growth marketing,
								and human resources services to extend and build your capacity at each stage of your
								startup journey,{' '}
								<strong className='bold-text-8'>leaning in on projects</strong> or
								<strong className='bold-text-9'> {' '}out of engagements</strong> as needed.
								<span className='text-span-6'>
									business consulting services, bookkeeping services, human resource management,
									strategic human resource management
								</span>
							</p>
							<div className='button-spacing'>
								<a href='/contact' className='button-dark w-button'>
									Tell us your founder story
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='section-testimonials'>
				<div className='container-small'>
					<div className='quote-wrapper'>
						<img
							src='images/Quote-Icon.svg'
							loading='lazy'
							alt=''
							className='quote-image no-invert'
						/>
						<h4 className='heading-14'>
							Backtify was critical to our preparation to raising a <br />
							$9.4M Series A with Accel Partners.
						</h4>
						<div className='testimonial-name'>
							Mark Hamilton, CEO &amp; Partner, {' '}
							<em>TUNE Inc.</em>
						</div>
						<img
							src='images/Quote-Icon.svg'
							loading='lazy'
							alt=''
							className='quote-image no-invert'
						/>
					</div>
				</div>
			</div>

			<div className='section-content big-padding'>
				<div className='container-large-2'>
					<div className='title-wrapper-left'>
						<h2 className='h3-title small'>How we can help</h2>
					</div>
					<div className='w-layout-grid grid-timeline'>
						<div className='card-timeline-2'>
							<div>
								<img
									src='images/img-card-one.jpeg'
									loading='lazy'
									srcset='images/finance-GettyImages-1315371152-p-500.jpeg 500w, images/finance-GettyImages-1315371152-p-800.jpeg 800w, images/finance-GettyImages-1315371152-p-1080.jpeg 1080w, images/finance-GettyImages-1315371152-p-1600.jpeg 1600w, images/finance-GettyImages-1315371152-p-1772.jpeg 1772w'
									alt='accounting &amp; finance professional using calculator'
									sizes='90vw'
									className='image-6'
								/>
							</div>
							<div className='div-block-94'>
								<h5 className='h5-heading center'>
									<strong>Financial Consulting &amp; Accounting Services</strong>
								</h5>
								<a
									href='/what-we-do#finance-accounting'
									className='button-primary margin-top w-button'
								>
									Learn more
								</a>
							</div>
						</div>
						<div className='card-timeline-2'>
							<div>
								<img
									src='images/img-card-two.jpeg'
									loading='lazy'
									srcset='images/Growth-GettyImages-639428672-p-500.jpeg 500w, images/Growth-GettyImages-639428672-p-800.jpeg 800w, images/Growth-GettyImages-639428672-p-1080.jpeg 1080w, images/Growth-GettyImages-639428672-p-1600.jpeg 1600w, images/Growth-GettyImages-639428672-p-1755.jpg 1755w'
									alt='strategic growth woman planning'
									sizes='90vw'
									className='image-6'
								/>
							</div>
							<div className='div-block-94'>
								<h5 className='h5-heading center'>Strategic Growth &amp; Marketing </h5>
								<a
									href='/what-we-do#strategic-growth'
									className='button-primary margin-top w-button'
								>
									Learn more
								</a>
							</div>
						</div>
						<div className='card-timeline-2'>
							<img
								src='images/logoname-woman-compressed.jpg'
								loading='lazy'
								srcset='images/logoname-woman-compressed-p-500.jpeg 500w, images/logoname-woman-compressed-p-1773.jpg 1773w'
								alt='woman in human resources'
								sizes='90vw'
								className='image-6'
							/>
							<div className='div-block-94'>
								<h5 className='h5-heading center'>
									Human Resources &amp;&nbsp;People Operations{' '}
								</h5>
								<a
									href='/what-we-do#human-resources'
									className='button-primary margin-top w-button'
								>
									Learn more
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HomeScreen;
