import React from 'react';

const TermsScreen = () => {
	return (
		<>
			<div className='template-header'>
				<div className='container'>
					<div className='title-wrapper-centre'>
						<h1>Terms &amp; Conditions</h1>
					</div>
				</div>
			</div>

			<div className='template-section'>
				<div className='container-small'>
					<div className='template-detail-block'>
						<p className='paragraph-3'>
							These terms and conditions ("Terms and Conditions") constitute a binding legal
							agreement between you and Backtify ("Backtify") governing your access to and use of
							the website and its Content (as defined below) located at www.Backtify.org (the
							"Website").
							<br />
							<br />
							<strong>BY USING OUR SITE YOU ACCEPT THESE TERMS</strong>
							<br />
							By using our Website, you confirm that you accept these Terms of Use and that you
							agree to comply with them. If you do not agree to these Terms of Use, you must not use
							our Website. We recommend that you print a copy of these Terms of Use for future
							reference. Our Website may be used for your informational purposes only. Our Website
							is made available free of charge. These Terms of Use may refer to the following
							additional terms, which also apply to your use of our Website:
							<br />
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our&nbsp;
							<a href='/privacy-policy'>
								<strong>Privacy Policy</strong>
							</a>
							,&nbsp;which sets out the terms on which we process any personal data we collect from
							you, or that you provide to us. By using our Website, you consent to such processing
							and you warrant that all data provided by you is accurate.
							<br />
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our&nbsp;Cookie Policy, which
							sets out information about the cookies on our Website. Further information may be
							found on our&nbsp;
							<a href='/privacy-policy'>
								<strong>Privacy page</strong>
							</a>
							.&nbsp;
							<br />
							<br />‍
							<strong>
								MINORS
								<br />‍
							</strong>
							PERSONS UNDER THE AGE OF 18 MUST ONLY USE THE WEBSITE AND PROVIDE PERSONAL INFORMATION
							IF THEY HAVE THE CONSENT AND SUPERVISION OF A PARENT OR GUARDIAN.
							<br />
							<br />‍
							<strong>
								WE MAY MAKE CHANGES
								<br />‍
							</strong>
							We amend these Terms of Use from time to time. Every time you wish to use our Website,
							please check these Terms of Use to ensure you understand the terms that apply at that
							time. These terms were most recently updated on November 24, 2018.
							<br />‍<br />‍
							<strong>
								WE MAY MAKE CHANGES TO, SUSPEND OR WITHDRAW OUR WEBSITE
								<br />‍
							</strong>
							We may update and change our Website from time to time to reflect changes to our
							services, our users’ needs and our business priorities.We do not guarantee that our
							Website, or any content on it, will always be available or be uninterrupted. We may
							suspend, withdraw or restrict the availability of all or any part of our Website for
							business and operational reasons.You are also responsible for ensuring that all
							persons who access our Website through your internet connection are aware of these
							Terms of Use and other applicable terms and conditions, and that they comply with
							them.
							<br />
							<br />‍
							<strong>
								USE OF WEBSITE
								<br />‍
							</strong>
							You may not use our Website to commit any violation of federal, state, local, or
							international laws, regulations, or other governmental requirements. We reserve the
							right to report any activity that may violate any law or regulation to appropriate law
							enforcement officials, regulators, or other third parties, without prior notice to
							you, and we will co-operate with such authorities by disclosing your identity to them.
							In the event of such a violation, your right to use our Website will cease
							immediately.You are prohibited from violating, or attempting to violate, the security
							of our Website. Any such violation may result in criminal and civil liabilities to
							you. Examples of prohibited security violations include, but are not limited
							to:·&nbsp;logging into a server or account that you are not authorized to
							access;·&nbsp;accessing data or taking any action to obtain services not intended for
							you or your use;·attempting to probe, scan, or test the vulnerability of any system,
							subsystem, or network related to the Website or to any&nbsp;Backtify&nbsp;computer or
							computer system;·&nbsp;tampering, hacking, modifying, or otherwise corrupting or
							breaching security or authentication measures;·&nbsp;introducing or transmitting
							material that contains viruses, Trojan horses, worms, time bombs, logic bombs,
							cancelbots, or other technologically malicious or harmful computer programming
							routines or engines with the intent or effect of damaging, destroying, disrupting, or
							otherwise impairing, a computer’s functionality or the operation of the
							Website;·&nbsp;interfering with, intercepting, or expropriating any system, data, or
							information;·&nbsp;interfering with service to any user, host, or network including,
							but not limited to, by means of overloading, “flooding,” “mailbombing,” or “crashing”
							any system, subsystem, or network related to the Website or otherwise;
							and·&nbsp;attacking the Website via a denial-of-service attack or a distributed
							denial-of service attack.
							<strong>
								&nbsp;
								<br />
								<br />
								FUNCTIONALITY DISCLAIMER
								<br />‍
							</strong>
							We do not guarantee that the functions contained in the Website will be uninterrupted
							or error-free, that this Website or its server will be free of viruses or other
							harmful components, or that defects will be corrected even if We are aware of them.
							You are responsible for configuring your information technology, computer programs and
							platform to access our Website. You should use your own virus protection software. The
							material in this Website may include technical inaccuracies or typographical errors.
							<br />
							<strong>
								<br />
								CONTENT
								<br />‍
							</strong>
							We are the owner or the licensee of all intellectual property rights in our Website,
							and in the material published on it. Those works are protected by copyright laws and
							treaties around the world. All such rights are reserved. You are authorized to copy,
							print, or distribute such content only as follows:·You may print individual pages, but
							you may not copy or download a large portion of the Website (e.g., no bots, web
							crawlers, spiders, etc. that “harvest” the Website).·You may download, print,
							distribute, and use pages from the Website for your personal informational,
							non-commercial purposes only. Any copies of such documents or pages must not alter the
							original Website content and must retain any copyright notices appearing
							therein.·&nbsp;Our status (and that of any identified contributors) as the authors of
							content on our Website must always be acknowledged.·&nbsp;You may not frame this
							Website or any portion thereof. Linking to this Website in a way that does not
							otherwise infringe upon our trademark interests is permitted, provided that (i) the
							link does not falsely imply or suggest that We have endorsed, or are affiliated with,
							the linked website, and (ii) you follow the “rules about linking to our site” which
							are stated below.· You must not modify the paper or digital copies of any materials
							you have printed off or downloaded in any way, and you must not use any illustrations,
							photographs, video or audio sequences or any graphics separately from any accompanying
							text.·&nbsp;You must not use any part of the content on our Website for commercial
							purposes without obtaining a license to do so from us or our licensors.·&nbsp;If you
							print off, copy or download any part of our Website in breach of these Terms of Use,
							your right to use our Website will cease immediately and you must, at our option,
							return or destroy any copies of the materials you have made.
							<br />
							We reserve the right to terminate all or any portion of the above authorizations to
							you at any time in our sole discretion.Except as expressly provided above, you may not
							otherwise copy, display, download, distribute, modify, reproduce, republish,
							retransmit or create derivative works based upon any information, content, or
							materials contained in the Website or any medium (including electronic or hard copy)
							without our express prior written consent. Nothing contained on the Website shall be
							construed as conferring by implication, estoppel, or otherwise any license or right
							under any intellectual property right of&nbsp;Backtify&nbsp;Holdings or any third party,
							except as expressly provided above.&nbsp;
							<br />
							<br />‍
							<strong>
								TRADEMARKS
								<br />‍
							</strong>
							The trademarks, service marks, and logos of&nbsp;Backtify Holdings, and its affiliates,
							used and displayed on the Website are our registered and unregistered trademarks.
							Requests to use trademarks owned by other companies which may be mentioned on our
							Website should be directed to such other companies. We actively and vigorously enforce
							our intellectual property rights. Our trademarks, service marks, and logos may not be
							used in any way, including in advertising or publicity pertaining to distribution of
							materials on our Website, without our prior written permission.&nbsp;
							<br />
							<strong>
								<br />
								WEBSITES WE LINK TO
								<br />‍
							</strong>
							We may provide links on the Website that allow you to connect with third party
							websites. We have no control over the contents of those sites or resources and We
							provide them to you for your information only. You access these sites at your own
							risk. We are not responsible for the contents of any linked site, nor does the
							appearance of a link imply our endorsement of them. Such links should not be
							interpreted as approval by us of those linked websites or information you may obtain
							from them.
							<br />
							<strong>
								<br />
								LINKING TO OUR SITE
								<br />‍
							</strong>
							You may link to our home page, provided you do so in a way that is fair and legal and
							does not damage our reputation or take advantage of it. You must not establish a link
							in such a way as to suggest any form of association, approval or endorsement on our
							part where none exists. You must not establish a link to our Website in any website
							that is not owned by you. Our Website must not be framed on any other site, nor may
							you create a link to any part of our Website other than the home page. We reserve the
							right to withdraw linking permission without notice.If you wish to link to or make any
							use of content on our Website other than that set out above, please contact us.&nbsp;
							<br />
							<br />‍
							<strong>
								SUBMISSIONS
								<br />‍
							</strong>
							All submissions, documents, materials, postings, comments, remarks, suggestions,
							ideas, graphics, or other information communicated from users to this Website (
							<strong>“User Material”</strong>) are, and will forever be, the property and
							intellectual property of Backtify, and you hereby assign all your intellectual property
							rights in such User Material to Us. We will not be required to treat any such User
							Material as confidential. Further, We will be entitled to use any such User Material
							for any purpose whatsoever, commercial or otherwise, without compensation to you or
							anyone else. Do not submit User Material that is unlawful, defamatory, abusive,
							obscene, not in accordance with these Terms of Use, or that will violate any right of
							any third party, including copyright, trademark, privacy, or other personal or
							proprietary right(s). If you do, We shall be entitled to remove such User Material
							with or without notice to you.
							<strong>
								&nbsp;
								<br />
								<br />
								PRIVACY
								<br />‍
							</strong>
							For an explanation of our policies related to the collection, use, and storage of
							information, please read our&nbsp;
							<a href='/privacy-policy'>
								<strong>Privacy Policy</strong>
							</a>
							.&nbsp;
							<br />
							<br />‍
							<strong>
								INDEMNIFICATION
								<br />‍
							</strong>
							You agree to indemnify, defend, and hold harmless &nbsp;Backtify, its affiliates,
							holdings, officers, directors, employees, agents, suppliers, and third-party partners
							from and against all losses, expenses, damages, and costs, including reasonable
							attorneys’ fees and expenses, resulting or arising from, or incurred in connection
							with, any violations by you of these Terms of Use.&nbsp;
							<strong>LIMITATION OF LIABILITY</strong>WE DO NOT EXCLUDE OR LIMIT IN ANY WAY OUR
							LIABILITY TO YOU WHERE IT WOULD BE UNLAWFUL TO DO SO.&nbsp;DIRECT APPS, INC.,
							DBA&nbsp;Backtify HOLDINGS&nbsp;AND ITS AFFILIATES WILL NOT BE LIABLE FOR ANY DAMAGES OR
							INJURY, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR
							PUNITIVE DAMAGES THAT RESULT FROM THE USE OF (OR THE INABILITY TO USE) THIS WEBSITE
							AND ITS CONTENTS, OR A WEBSITE LINKED TO THIS WEBSITE, INCLUDING ANY DAMAGES OR INJURY
							CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN
							OPERATION, COMPUTER VIRUS, LINE FAILURE, OR OTHER COMPUTER MALFUNCTION.
							<strong>
								&nbsp;
								<br />
								<br />
								DISCLAIMER
								<br />‍
							</strong>
							THIS WEBSITE IS PROVIDED BY Backtify ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE CONTENT
							ON OUR WEBSITE IS PROVIDED FOR GENERAL INFORMATION ONLY. IT IS NOT INTENDED TO AMOUNT
							TO ADVICE ON WHICH YOU SHOULD RELY. YOU MUST OBTAIN PROFESSIONAL OR SPECIALIST ADVICE
							BEFORE TAKING, OR REFRAINING FROM, ANY ACTION ON THE BASIS OF THE CONTENT ON OUR
							WEBSITE.Backtify HOLDINGS / DIRECTAPPS MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY
							KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS WEBSITE OR THE INFORMATION
							INCLUDED ON THIS WEBSITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS WEBSITE IS AT YOUR
							SOLE RISK. Backtify DOES NOT WARRANT THAT THE INFORMATION IN THIS WEBSITE IS ACCURATE,
							COMPLETE, CURRENT, RELIABLE, OR CORRECT, THAT THIS WEBSITE WILL BE AVAILABLE AT ANY
							PARTICULAR TIME OR LOCATION, OR THAT THE WEBSITE IS FREE OF HARMFUL COMPONENTS.
							<br />
							<strong>
								<br />
								SEVERABILITY
								<br />‍
							</strong>
							If any provision of these Terms of Use is deemed unlawful, void, or for any reason
							unenforceable, then that provision will be deemed severable from these Terms of Use
							and will not affect the validity and enforceability of the remaining provisions.
							<br />
							<strong>
								<br />
								ENTIRE AGREEMENT
								<br />‍
							</strong>
							These Terms and Conditions constitute the entire agreement between you and Backtify with
							respect to the subject matter of these Terms and Conditions and supersedes and
							replaces all prior or contemporaneous understandings or agreements, written or oral,
							regarding that subject matter. Any waiver of any provision of these Terms and
							Conditions shall be effective only if in writing and signed by Backtify. Any failure to
							enforce any provision of these Terms and Conditions shall not constitute a waiver of a
							future breach of that or any other provision of these Terms and Conditions.&nbsp;
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default TermsScreen;
