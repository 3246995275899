import React from 'react';

const ConsumerScreen = () => {
	return (
		<>
			<div id='hero-overlay' className='section-hero-clients wf-section'>
				<div className='centered-container w-container'>
					<h1 className='heading-5'>Our clients are driving the innovation economy</h1>
					<div className='body-large left-align text-color-white'>
						Inventing the future, from gaming to life sciences, SAAS to robotics, and biotech to
						medtech.
					</div>
				</div>
			</div>

			<div className='div-block-91'>
				<div className='container-large sticky-nav'>
					<div className='section-sticky-sub-nav'>
						<div className='grid-sticky-page-nav-clients'>
							<a
								href='#SAAS-Software'
								id='w-node-_2605e380-bf18-08dc-18a1-0606ab813bd0-84fdf23f'
								className='button-dark sticky-nav w-inline-block'
							>
								<div className='text-block-17'>SAAS &amp; Software</div>
							</a>
							<a
								href='#bio-medtech-healthtech'
								id='w-node-_3fb82eeb-4a80-4019-4a04-a5d31408bb7c-84fdf23f'
								className='button-dark sticky-nav w-inline-block'
							>
								<div className='text-block-17'>Biotech, Medtech, &amp; Healthtech</div>
							</a>
							<a
								href='#Gaming'
								id='w-node-ffc54efd-ac46-fcb5-c1db-aadce544ddf1-84fdf23f'
								className='button-dark sticky-nav w-inline-block'
							>
								<div className='text-block-17'>Gaming</div>
							</a>
							<a
								href='#Consumer'
								id='w-node-_5196f0ea-f804-b295-f358-00e8167ce6d4-84fdf23f'
								className='button-dark sticky-nav w-inline-block'
							>
								<div className='text-block-17'>Consumer &amp; Other Innovations</div>
							</a>
						</div>
					</div>
				</div>
				<div className='section-contact'>
					<div className='container-3'>
						<div className='container-large-left-align'>
							<div className='title-wrapper-centre'>
								<h2 className='h3-title center'>
									Guiding the startups that power the innovation economy
								</h2>
								<p className='body-large-2'>
									Our clients are delivering the art of the possible with courage and conviction. We
									provide the tools, insights and talent entrepreneurs needed to navigate their
									chosen path.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='container-large-client-logos'></div>
				<section id='SAAS-Software' className='section-full-bleed-saas'>
					<h2 className='white center'>SAAS &amp; Software</h2>
					<div className='body-large no-margin-top center white'>
						We are headquarted in Seattle, known as "cloud city," not just for the weather but for
						being home to industry leaders in cloud services. &nbsp;Powering efficiency for the
						enterprise, automating and simplifying lives of consumers, software is our sweet spot.
						Our region, and our clients, lead in machine learning, artificial intelligence, SaaS
						companies and more.
					</div>
					<a href='/contact' className='button-primary who w-button'>
						<strong>Meet with our CXO </strong>
					</a>
					<div
						data-delay='10000'
						data-animation='slide'
						className='slider-in-section w-slider'
						data-autoplay='true'
						data-easing='ease'
						data-hide-arrows='false'
						data-disable-swipe='false'
						data-autoplay-limit='0'
						data-nav-spacing='3'
						data-duration='500'
						data-infinite='true'
						role='region'
						aria-label='carousel'
					>
						<div className='w-slider-mask' id='w-slider-mask-0'>
							<div
								className='w-slide'
								aria-label='1 of 2'
								role='group'
								aria-hidden='true'
								style={{
									transform: 'translateX(-1280px)',
									opacity: 1,
									transition: 'transform 500ms ease 0s',
								}}
							>
								<div className='div-block-89' aria-hidden='true'>
									<div id='w-node-d9c8135b-06e9-4e62-c894-a93d52ec6f67-84fdf23f' aria-hidden='true'>
										<img src='#' loading='lazy' alt='rigid logo 2' aria-hidden='true' />
									</div>
									<div id='w-node-d9c8135b-06e9-4e62-c894-a93d52ec6f69-84fdf23f' aria-hidden='true'>
										<div className='body-large-bold text-color-white' aria-hidden='true'>
											"Insert quote here"
											<br aria-hidden='true' />- Nathan Ziemanski
										</div>
									</div>
								</div>
							</div>
							<div
								className='w-slide'
								aria-label='2 of 2'
								role='group'
								style={{
									transform: 'translateX(-1280px)',
									opacity: 1,
									transition: 'transform 500ms ease 0s',
								}}
							>
								<div className='div-block-89'>
									<div id='w-node-d9c8135b-06e9-4e62-c894-a93d52ec6f70-84fdf23f'>
										<img src='#' loading='lazy' alt='rigid logo' />
									</div>
									<div id='w-node-d9c8135b-06e9-4e62-c894-a93d52ec6f72-84fdf23f'>
										<div className='body-large-bold text-color-white'>
											"Insert quote here"
											<br />- Nathan Ziemanski
										</div>
									</div>
								</div>
							</div>
							<div
								aria-live='off'
								aria-atomic='true'
								className='w-slider-aria-label'
								data-wf-ignore=''
							>
								Slide 2 of 2.
							</div>
						</div>
						<div
							className='w-slider-arrow-left'
							role='button'
							tabIndex='0'
							aria-controls='w-slider-mask-0'
							aria-label='previous slide'
						>
							<div className='w-icon-slider-left'></div>
						</div>
						<div
							className='w-slider-arrow-right'
							role='button'
							tabIndex='0'
							aria-controls='w-slider-mask-0'
							aria-label='next slide'
						>
							<div className='w-icon-slider-right'></div>
						</div>
						<div className='slide-nav w-slider-nav w-round'>
							<div
								className='w-slider-dot'
								data-wf-ignore=''
								aria-label='Show slide 1 of 2'
								aria-pressed='false'
								role='button'
								tabIndex='-1'
								style={{ marginLeft: '3px', marginRight: '3px' }}
							></div>
							<div
								className='w-slider-dot w-active'
								data-wf-ignore=''
								aria-label='Show slide 2 of 2'
								aria-pressed='true'
								role='button'
								tabIndex='0'
								style={{ marginLeft: '3px', marginRight: '3px' }}
							></div>
						</div>
					</div>
				</section>
				<section id='bio-medtech-healthtech' className='section-full-bleed-biotech'>
					<h2 className='white center'>Biotech, Medtech, &amp; Healthtech</h2>
					<div className='body-large no-margin-top center white'>
						With world-leading research and development institutions and centers of excellence in
						global health and immuno-oncology, our home town of Seattle may well cure cancer, and is
						certainly improving health outcomes around the globe. Inventors, innovators and
						investors unite to deliver software, devices, drug delivery and discovery, and all the
						"picks and shovels" that support this ecosystem of biotech companies. Our team supports
						you as you navigate regulations, grants, and milestones on your journey to deliver
						solutions that are changing the world.
					</div>
					<a href='/contact' className='button-primary who extra w-button'>
						<strong>Meet with our CXO</strong>
					</a>
					<div
						data-delay='10000'
						data-animation='slide'
						className='slider-in-section w-slider'
						data-autoplay='true'
						data-easing='ease'
						data-hide-arrows='false'
						data-disable-swipe='false'
						data-autoplay-limit='0'
						data-nav-spacing='3'
						data-duration='500'
						data-infinite='true'
						id='w-node-_68307fbb-37f0-e541-5dc3-366562d91973-84fdf23f'
						role='region'
						aria-label='carousel'
					>
						<div className='w-slider-mask' id='w-slider-mask-1'>
							<div
								className='w-slide'
								aria-label='1 of 2'
								role='group'
								aria-hidden='true'
								style={{
									transform: 'translateX(-1280px)',
									opacity: 1,
									transition: 'transform 500ms ease 0s',
								}}
							>
								<div className='div-block-89' aria-hidden='true'>
									<div
										id='w-node-_68307fbb-37f0-e541-5dc3-366562d91977-84fdf23f'
										aria-hidden='true'
									>
										<img src='#' loading='lazy' alt='rigid logo' aria-hidden='true' />
									</div>
									<div
										id='w-node-_68307fbb-37f0-e541-5dc3-366562d91979-84fdf23f'
										aria-hidden='true'
									>
										<div className='body-large-bold text-color-white' aria-hidden='true'>
											"Insert quote here"
											<br aria-hidden='true' />- Nathan Ziemanski
										</div>
									</div>
								</div>
							</div>
							<div
								className='w-slide'
								aria-label='2 of 2'
								role='group'
								style={{
									transform: 'translateX(-1280px)',
									opacity: 1,
									transition: 'transform 500ms ease 0s',
								}}
							>
								<div className='div-block-89'>
									<div id='w-node-_68307fbb-37f0-e541-5dc3-366562d91980-84fdf23f'>
										<img src='#' loading='lazy' alt='rigid logo' />
									</div>
									<div id='w-node-_68307fbb-37f0-e541-5dc3-366562d91982-84fdf23f'>
										<div className='body-large-bold text-color-white'>
											"Insert quote here"
											<br />- Nathan Ziemanski
										</div>
									</div>
								</div>
							</div>
							<div
								aria-live='off'
								aria-atomic='true'
								className='w-slider-aria-label'
								data-wf-ignore=''
							>
								Slide 2 of 2.
							</div>
						</div>
						<div
							className='w-slider-arrow-left'
							role='button'
							tabIndex='0'
							aria-controls='w-slider-mask-1'
							aria-label='previous slide'
						>
							<div className='w-icon-slider-left'></div>
						</div>
						<div
							className='w-slider-arrow-right'
							role='button'
							tabIndex='0'
							aria-controls='w-slider-mask-1'
							aria-label='next slide'
						>
							<div className='w-icon-slider-right'></div>
						</div>
						<div className='slide-nav w-slider-nav w-round'>
							<div
								className='w-slider-dot'
								data-wf-ignore=''
								aria-label='Show slide 1 of 2'
								aria-pressed='false'
								role='button'
								tabIndex='-1'
								style={{ marginLeft: '3px', marginRight: '3px' }}
							></div>
							<div
								className='w-slider-dot w-active'
								data-wf-ignore=''
								aria-label='Show slide 2 of 2'
								aria-pressed='true'
								role='button'
								tabIndex='0'
								style={{ marginLeft: '3px', marginRight: '3px' }}
							></div>
						</div>
					</div>
				</section>
				<section id='Gaming' className='section-full-bleed-gaming'>
					<h2 className='white'>Gaming</h2>
					<div className='body-large no-margin-top center white'>
						Whether you love developing games or playing them, immersing yourself in augmented
						reality, or engaging in the creative economy, Seattle is the place to be. Our firm grew
						up in one of the gaming capitals of the world, and our consultants are well-versed in
						supporting gamers everywhere, from revenue recognition and royalties to partnerships and
						guerrilla campaigns.
					</div>
					<a href='/contact' className='button-primary who w-button'>
						<strong>Meet with our CXO</strong>
					</a>
					<div
						data-delay='10000'
						data-animation='slide'
						className='slider-in-section w-slider'
						data-autoplay='true'
						data-easing='ease'
						data-hide-arrows='false'
						data-disable-swipe='false'
						data-autoplay-limit='0'
						data-nav-spacing='3'
						data-duration='500'
						data-infinite='true'
						role='region'
						aria-label='carousel'
					>
						<div className='w-slider-mask' id='w-slider-mask-2'>
							<div
								className='w-slide'
								aria-label='1 of 2'
								role='group'
								aria-hidden='true'
								style={{
									transform: 'translateX(-1280px)',
									opacity: 1,
									transition: 'transform 500ms ease 0s',
								}}
							>
								<div className='div-block-89' aria-hidden='true'>
									<div
										id='w-node-_67ed4ac6-fdf0-b98b-1994-9227f7ffb11e-84fdf23f'
										aria-hidden='true'
									>
										<img src='#' loading='lazy' alt='rigid logo' aria-hidden='true' />
									</div>
									<div
										id='w-node-_67ed4ac6-fdf0-b98b-1994-9227f7ffb120-84fdf23f'
										aria-hidden='true'
									>
										<div className='body-large-bold text-color-white' aria-hidden='true'>
											"Insert quote here"
											<br aria-hidden='true' />- Nathan Ziemanski
										</div>
									</div>
								</div>
							</div>
							<div
								className='w-slide'
								aria-label='2 of 2'
								role='group'
								style={{
									transform: 'translateX(-1280px)',
									opacity: 1,
									transition: 'transform 500ms ease 0s',
								}}
							>
								<div className='div-block-89'>
									<div id='w-node-_67ed4ac6-fdf0-b98b-1994-9227f7ffb127-84fdf23f'>
										<img src='#' loading='lazy' alt='rigid logo' />
									</div>
									<div id='w-node-_67ed4ac6-fdf0-b98b-1994-9227f7ffb129-84fdf23f'>
										<div className='body-large-bold text-color-white'>
											"Insert quote here"
											<br />- Nathan Ziemanski
										</div>
									</div>
								</div>
							</div>
							<div
								aria-live='off'
								aria-atomic='true'
								className='w-slider-aria-label'
								data-wf-ignore=''
							>
								Slide 2 of 2.
							</div>
						</div>
						<div
							className='w-slider-arrow-left'
							role='button'
							tabIndex='0'
							aria-controls='w-slider-mask-2'
							aria-label='previous slide'
						>
							<div className='w-icon-slider-left'></div>
						</div>
						<div
							className='w-slider-arrow-right'
							role='button'
							tabIndex='0'
							aria-controls='w-slider-mask-2'
							aria-label='next slide'
						>
							<div className='w-icon-slider-right'></div>
						</div>
						<div className='slide-nav w-slider-nav w-round'>
							<div
								className='w-slider-dot'
								data-wf-ignore=''
								aria-label='Show slide 1 of 2'
								aria-pressed='false'
								role='button'
								tabIndex='-1'
								style={{ marginLeft: '3px', marginRight: '3px' }}
							></div>
							<div
								className='w-slider-dot w-active'
								data-wf-ignore=''
								aria-label='Show slide 2 of 2'
								aria-pressed='true'
								role='button'
								tabIndex='0'
								style={{ marginLeft: '3px', marginRight: '3px' }}
							></div>
						</div>
					</div>
				</section>
				<div
					id='w-node-bf39a21b-0468-aae0-e445-19c7f911c6e7-84fdf23f'
					className='container-large-client-logos'
				></div>
				<section id='Consumer' className='div-block-90'>
					<h2 className='white center'>Consumer &amp; Other Innovations</h2>
					<div className='body-large no-margin-top center white'>
						We support innovators who are defining, disrupting and delighting enterprises and
						consumers everywhere. &nbsp;From ecommerce to robotics, smart homes to social video, our
						clients are inventing the future, and we love partnering in their success.
					</div>
					<a href='/contact' className='button-primary who w-button'>
						<strong>Meet with our CXO</strong>
					</a>
					<div
						data-delay='10000'
						data-animation='slide'
						className='slider-in-section w-slider'
						data-autoplay='true'
						data-easing='ease'
						data-hide-arrows='false'
						data-disable-swipe='false'
						data-autoplay-limit='0'
						data-nav-spacing='3'
						data-duration='500'
						data-infinite='true'
						role='region'
						aria-label='carousel'
					>
						<div className='w-slider-mask' id='w-slider-mask-3'>
							<div
								className='w-slide'
								aria-label='1 of 2'
								role='group'
								aria-hidden='true'
								style={{
									transform: 'translateX(-1280px)',
									opacity: 1,
									transition: 'transform 500ms ease 0s',
								}}
							>
								<div className='div-block-89' aria-hidden='true'>
									<div id='w-node-c669ad42-a421-2a23-a5fc-40104b4e13ed-84fdf23f' aria-hidden='true'>
										<img
											src='images/624b784eb5d6f379b7b96c02_Logo%202.svg'
											loading='lazy'
											alt='rigid logo'
											aria-hidden='true'
										/>
									</div>
									<div id='w-node-c669ad42-a421-2a23-a5fc-40104b4e13ef-84fdf23f' aria-hidden='true'>
										<div className='body-large-bold text-color-white' aria-hidden='true'>
											"Insert quote here"
											<br aria-hidden='true' />- Nathan Ziemanski
										</div>
									</div>
								</div>
							</div>
							<div
								className='w-slide'
								aria-label='2 of 2'
								role='group'
								style={{
									transform: 'translateX(-1280px)',
									opacity: 1,
									transition: 'transform 500ms ease 0s',
								}}
							>
								<div className='div-block-89'>
									<div id='w-node-c669ad42-a421-2a23-a5fc-40104b4e13f6-84fdf23f'>
										<img
											src='images/624b784eb5d6f379b7b96c02_Logo%202.svg'
											loading='lazy'
											alt='rigid logo'
										/>
									</div>
									<div id='w-node-c669ad42-a421-2a23-a5fc-40104b4e13f8-84fdf23f'>
										<div className='body-large-bold text-color-white'>
											"Insert quote here"
											<br />- Nathan Ziemanski
										</div>
									</div>
								</div>
							</div>
							<div
								aria-live='off'
								aria-atomic='true'
								className='w-slider-aria-label'
								data-wf-ignore=''
							>
								Slide 2 of 2.
							</div>
						</div>
						<div
							className='w-slider-arrow-left'
							role='button'
							tabIndex='0'
							aria-controls='w-slider-mask-3'
							aria-label='previous slide'
						>
							<div className='w-icon-slider-left'></div>
						</div>
						<div
							className='w-slider-arrow-right'
							role='button'
							tabIndex='0'
							aria-controls='w-slider-mask-3'
							aria-label='next slide'
						>
							<div className='w-icon-slider-right'></div>
						</div>
						<div className='slide-nav w-slider-nav w-round'>
							<div
								className='w-slider-dot'
								data-wf-ignore=''
								aria-label='Show slide 1 of 2'
								aria-pressed='false'
								role='button'
								tabIndex='-1'
								style={{ marginLeft: '3px', marginRight: '3px' }}
							></div>
							<div
								className='w-slider-dot w-active'
								data-wf-ignore=''
								aria-label='Show slide 2 of 2'
								aria-pressed='true'
								role='button'
								tabIndex='0'
								style={{ marginLeft: '3px', marginRight: '3px' }}
							></div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default ConsumerScreen;
